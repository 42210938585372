import { Game } from "./lib/court_alloc"
import React from "react"
import GameForm from "./components/GameForm/"
import { CssBaseline, Fade } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridValueGetterParams,
} from "@material-ui/data-grid"
import Container from "@material-ui/core/Container"

import "./App.css"
const useStyles = makeStyles({
  gameForm: {
    // color: "white",
  },
  tableContainer: {
    height: "100%",
  },
  loader: {
    position: "absolute",
    left: "50%",
    top: "35%",
    display: "none",
    zIndex: 1000,
    height: "31px",
    width: "31px",
  },
})
interface GameOptions {
  players: number
  rounds: number
  courts: number
  PPC: number
}

function App() {
  //@ts-ignore
  let game: Game
  let gameOptions: GameOptions
  let [columns, setColumns] = React.useState<GridColDef[]>([
    {
      field: "id",
      headerName: "Id",
      type: "number",
      width: 150,
    },
  ])
  let [rows, setRows] = React.useState<GridRowsProp>([{ id: 0 }])
  const classes = useStyles()
  const [initalised, setInitalised] = React.useState(false)
  // const [gameBoard, setGameBoard] = React.useState({})

  function StartGame({ players, rounds, courts, PPC }: any) {
    gameOptions = {
      players: parseInt(players),
      rounds: parseInt(rounds),
      courts: parseInt(courts),
      PPC: parseInt(PPC),
    }
    game = new Game(gameOptions)
    game.Calculate()
    setColumns([
      {
        field: "id",
        headerName: "Id",
        type: "number",
        width: 90,
      },
      {
        field: "games",
        headerName: "Games",
        type: "number",
        disableColumnMenu: true,
        width: 120,
      },
      ...game.roundBoard.map<GridColDef>((round, index) => {
        return {
          field: "round" + index.toString(),
          headerName: "Court " + (index + 1).toString(),
          hideSortIcons: true,
          disableColumnMenu: true,
          type: "number",
        }
      }),
    ])
    setRows([
      ...game.playerBoard.sort(game.SortPlayerbyId).map(player => {
        return {
          id: player.id + 1,
          games: player.games,
          ...player.courts.reduce((acc, court, index) => {
            return { ["round" + index.toString()]: court, ...acc }
          }, {}),
        }
      }),
    ])
    setInitalised(true)
  }

  return (
    <div className='App'>
      <CssBaseline />
      <Fade in={!initalised}>
        <GameForm gameInit={StartGame} />
      </Fade>

      <Fade in={initalised}>
        <Container className={classes.tableContainer}>
          <DataGrid columns={columns} rows={rows} autoHeight />
        </Container>
      </Fade>
    </div>
  )
}

export default App
