import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "auto",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    paper: {
      marginTop: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
)

interface Props {
  gameInit: CallableFunction
  style?: any
}
interface GameOptions {
  invalid(): boolean
  players: string
  rounds: string
  courts: string
  PPC: string
}
const initOptions: GameOptions = {
  invalid() {
    if (
      this.players.length == 0 ||
      this.rounds.length == 0 ||
      this.courts.length == 0 ||
      this.PPC.length == 0
    )
      return true
    return false
  },
  players: "",
  rounds: "",
  courts: "",
  PPC: "",
}

function GameForm(props: Props) {
  const classes = useStyles()
  const [gameOptions, setGameOptions] = React.useState(initOptions)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGameOptions({ ...gameOptions, [event.target.name]: event.target.value })
  }
  return (
    <Container
      component='main'
      maxWidth='xs'
      style={props.style}
      className={classes.container}
    >
      <CssBaseline />
      <Paper className={classes.paper} elevation={20}>
        <Avatar className={classes.avatar}></Avatar>
        <Typography component='h1' variant='h5'>
          Set up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                autoFocus
                required
                name='players'
                type='number'
                id='players'
                variant='outlined'
                label='Players'
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name='courts'
                required
                type='number'
                id='courts'
                variant='outlined'
                label='Courts'
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                name='rounds'
                type='number'
                id='rounds'
                variant='outlined'
                label='Rounds'
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                name='PPC'
                type='number'
                id='playersPerCourt'
                variant='outlined'
                label='Players per Court'
                onChange={handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Button
            type='button'
            fullWidth
            variant='contained'
            color='primary'
            onClick={() => props.gameInit(gameOptions)}
            className={classes.submit}
            disabled={gameOptions.invalid()}
          >
            Calculate
          </Button>
        </form>
      </Paper>
    </Container>
  )
}

export default GameForm
