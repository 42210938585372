interface Player {
  id: number
  played: number[]
  courts: number[]
  games: number
}
interface GameOptions {
  players: number
  rounds: number
  courts: number
  PPC: number
}
export class Game {
  readonly playerBoard: Player[]
  readonly roundBoard: Player[][][]
  readonly players: number
  readonly rounds: number
  readonly courts: number
  readonly PPC: number

  constructor({ players, rounds, courts, PPC }: GameOptions) {
    this.rounds = rounds
    this.courts = courts
    this.PPC = PPC
    this.players = players
    this.playerBoard = new Array(this.players)
      .fill(null)
      .map((element, index) => {
        let newPlayer: Player = {
          id: index,
          played: [],
          courts: new Array(this.rounds).fill(null),
          games: 0,
        }
        return newPlayer
      })
    this.roundBoard = Array(this.rounds)
      .fill(null)
      .map(() =>
        Array(this.courts)
          .fill(null)
          .map(() => Array(0))
      )
  }
  private random = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min)) + min

  SortPlayerbyGames(a: Player, b: Player): number {
    if (a.games < b.games) return -1
    if (a.games > b.games) return 1
    return 0
  }
  SortPlayerbyId(a: Player, b: Player): number {
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return 0
  }

  AssignCourts(round: number) {
    let courtBoard = this.roundBoard[round].slice(
      0,
      Math.floor(this.players / this.PPC)
    )
    let playerPool = Array.from(this.playerBoard.sort(this.SortPlayerbyGames))

    while (
      courtBoard.reduce((prev, cur) => {
        if (cur.length < prev) return cur.length
        return prev
      }, this.courts) < this.courts
    ) {
      if (playerPool.length <= 0) break
      let player = playerPool[0]
      let randWeightedDistribution = courtBoard.map(court => {
        let courtWeight = this.PPC - court.length
        if (courtWeight != 0) {
          return (
            courtWeight /
            (court.filter(compPlayer => player.played.includes(compPlayer.id))
              .length || 1 * 2)
          )
        }
        return courtWeight
      })

      let multiplier =
        1000 / randWeightedDistribution.reduce((acc, cur) => cur + acc)
      let prev = 0
      randWeightedDistribution = randWeightedDistribution.map(RWdist => {
        prev += RWdist * multiplier
        return prev
      })
      let courtRand = this.random(1, 1000)
      let court: Player[]
      let courtNum: number
      for (courtNum = 0; courtNum < this.courts; courtNum++) {
        if (courtRand <= randWeightedDistribution[courtNum]) {
          break
        }
      }
      court = courtBoard[courtNum]
      court.push(player)
      player.courts[round] = courtNum
      player.games++
      player.played.push()
      playerPool.splice(0, 1)
    }
    courtBoard.map(court => {
      let otherPlayers = court.map(player => player.id)
      court.forEach(player => {
        player.played.push(...otherPlayers.filter(item => item != player.id))
      })
    })
  }
  Calculate() {
    this.roundBoard.map((val, index) => {
      this.AssignCourts(index)
    })
  }
}
